<script setup lang="ts">
  const props = defineProps<{
    items: [{
      description: string;
      imagePosition: string;
      linkLabel: string;
      subtitle: string;
      title: string;
      link: {
        title?: string,
        url: string;
      },
      img: {
        node: {
          mediaItemUrl: string;
        }
      }
    }],
    isDescHtml?: boolean;
  }>();
</script>

<template>
  <div class="container-xl mw-100">
    <template v-for="banner in props.items">
      <div :class="`row ${banner?.imagePosition === 'right' ? 'bg-wh-brown' : 'flex-row-reverse bg-white'}`">
        <div class="col-md-6 d-flex flex-column justify-content-center p-0">
          <div class="d-flex flex-column align-items-center py-md-5 py-7 px-5 px-md-7">
            <span class="subtitle mb-2">{{ banner?.subtitle }}</span>
            <h3 class="lh-base category-label text-center my-3">
              {{ banner?.title }}
            </h3>
            <div v-if="props.isDescHtml" v-html="banner.description" class="card-content text-center mt-3"></div>
            <span v-else class="card-content text-center mt-3">{{ banner.description }}</span>
            <a class="btn btn-outline-primary mt-4"
               aria-label="{{ banner?.link?.title || banner?.linkLabel }}"
               :href="banner?.link?.url">
              {{ banner?.link?.title || banner?.linkLabel }}
            </a>
          </div>
        </div>
        <div class="col-md-6 p-0">
          <NuxtImg loading="lazy"
                   quality="80"
                   height="250" width="250"
                   class="w-100 h-100 object-fit-cover mnh-500"
                   :src="banner?.img?.node?.mediaItemUrl" alt="image 1" format="webp"/>
        </div>
      </div>
    </template>
  </div>
</template>
